:root {
  --scroll-bar-width: 7px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: red !important; */
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* html, body, #app, #app>div {
  height: 100%;
} */

#mapid {
  height: 180px;
}

/* fix grid layout */
.MuiGrid-container {
  margin: 0 !important;
  width: 100% !important;
}

.main {
  height: 100% !important;
  /* background-color: green; */
}

/* .MuiToolbar-regular {
  min-height: 44px !important;
  height: 44px !important;
}

.makeStyles-drawerHeader-7 {
  min-height: 44px !important;
  height: 44px !important;
} */

/** Custom Scrollbar */
/* https://css-tricks.com/scrollbars-on-hover/ */

/* width */
.custom-scrollbar::-webkit-scrollbar {
  width: var(--scroll-bar-width);
  margin-right: 15px;
  /* background-color: red; */
}

/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f162;
  /* background-color: white; */
  margin-top: 5px;
  margin-bottom: 5px;
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.692);
  border-radius: 50px;
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgba(85, 85, 85, 0.719);
}

.custom-scrollbar {
  /* padding: 17px 0 17px 17px; */
  /* mask-image: linear-gradient(to top, transparent, black),
    linear-gradient(to left, transparent 0px, black 0px); */
  mask-size: 100% 20000px;
  mask-position: left bottom;
  -webkit-mask-image: linear-gradient(to top, transparent, black),
    linear-gradient(to left, transparent var(--scroll-bar-width), black 0px);
  -webkit-mask-size: 100% 20000px;
  -webkit-mask-position: left bottom;
  transition: mask-position 0.3s, -webkit-mask-position 0.3s;
}

.custom-scrollbar:hover {
  -webkit-mask-position: left top;
}

/** End Custom Scrollbar**/

.swal2-container {
  z-index: 99999 !important;
}